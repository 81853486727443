/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Cast ELS Income attribute response to work with updated LookupsState
 */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from '../../../redux/actions/incomeAttributes/incomeAttributes';
import { getLookups } from '../../../redux/actions/lookups/lookups';
import { setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { RootState } from '../../../redux/reducers/root';
import { IncomeAttributeState } from '../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer';
import { LookupsState } from '../../../redux/reducers/getLookups/lookupsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import CapitalCostTab from './CapitalCostTab';
import MenuState from '../../../types/menu/MenuState';
import 'react-tabs/style/react-tabs.css';
import "../PIE.css";
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';

export default function RevenueContainer(): JSX.Element {
  const { t } = useTranslation(['navigation', 'pie']);
  const dispatch = useDispatch();
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const tabList = menuState.activeItem.subItems;

  const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);

  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
        || incomeAttributesState.snapshotId !== Number(localStorage.getItem('currentSnapshotId') as string)
    ) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (saveData.saving) {
      const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
      dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes));
    }
  }, [dispatch, incomeAttributesState.data, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
        dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
  }, [dispatch, saveResultState]);

  const handleTabSelect = (index: number) => {
    dispatch(setNextActiveMenuItem({ ...menuState.activeItem, selectedTabIndex: index }));
  }

  useEffect(() => {
    if (!lookupState.success) {
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.success, lookupType]);

  return (
    <div className='tab-page-container'>
      {
          (menuState.activeItem.hasTabs && tabList) && (<>
            <Tabs selectedIndex={menuState.activeItem.selectedTabIndex}
              onSelect={handleTabSelect}>
              <TabList className={'tab-container'}>
                {tabList?.map(tab => {
                  return <Tab key={tab.id} selectedClassName='active-tab' className="tab" title={t(tab.name + ".tooltip", { ns: 'pie' })} >{t(tab.name)}</Tab>
                })}
              </TabList>
              {
                tabList?.map(tab => {
                  return <TabPanel key={tab.id}>
                    <CapitalCostTab
                      name={tab.name}
                      lookups={lookupState.lookups[3] ? lookupState.lookups[3][tab.name] : []}
                      incomeAttributes={(incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) ? (incomeAttributesState.data as Record<string, unknown>[]) : undefined} />
                  </TabPanel>
                })
              }
            </Tabs>
          </>)
      }
    </div>);
}