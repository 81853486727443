import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import Dropdown, { Option } from '../common/Dropdown/Dropdown';
import Asterisk from '../common/Asterisk/Asterisk';
import "./CommercialUnit.css";

interface CommercialUnitDropdownFieldProps {
    formData: Record<string, unknown>,
    name: string,
    errors: Record<string, string>,
    heading: string,
    title?: string,
    multiple?: boolean,
    options?: Option[],
    disabled?: boolean
}

export default function CommercialUnitDropdownField(props: CommercialUnitDropdownFieldProps): JSX.Element {
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);

    return (
        <>
            <tr>
                <td className='label-cell'>
                    {props.heading} <Asterisk />
                </td>
            </tr>
            <tr>
                <td>
                    <Dropdown name={props.name}
                        options={props.options ? props.options : elsLookupsState.dropdowns[props.name + "Options"] as Option[]}
                        value={props.formData[props.name] as string | undefined}
                        className='border'
                        title={props.title}
                        errorMessage={props.errors[props.name]}
                        hideErrorCross={true}
                        ariaLabel={props.heading}
                        testid={props.name + "-dropdown"}
                        multiple={props.multiple} 
                        disabled={props.disabled} />
                </td>
            </tr>
        </>
    );
}
