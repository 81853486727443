import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { RESET_GET_PROFILE_DETAILS } from '../../redux/actionTypes/actionsTypes';
import { setMenu, setNextActiveMenuItem } from '../../redux/actions/menu/menu';
import { getProfileDetails, putProfileDetails, resetPutProfileDetails } from '../../redux/actions/profile/profile';
import { resetSaving } from '../../redux/actions/saveData/saveData';
import { RootState } from '../../redux/reducers/root';
import { initialMenuItem } from '../../redux/reducers/getMenu/getMenuReducer';
import { ProfileDetailsState } from '../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer';
import { SaveResultState } from '../../redux/reducers/getSaveData/saveResultReducer';
import Asterisk from '../../components/common/Asterisk/Asterisk';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import RadioButton from '../../components/common/RadioGroup/RadioButton';
import MenuState from '../../types/menu/MenuState';
import ProfileDataType from '../../types/profile/ProfileData';
import BIPCommercialApartment from '../../assets/data/navigationList/BIPCommercialApartment';

export default function IncomeExpense(): JSX.Element {
    const { t } = useTranslation('contact_info')
    const dispatch = useDispatch()
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const propertyCode = localStorage.getItem("propertyCode") as string
    const showOwnerOccupied = ["402", "403", "409", "431", "433", "434", "435", "436"].includes(propertyCode)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
    const isInternalUser = localStorage.getItem('isInternalUser') ? true : false
    const currentSnapshotId = localStorage.getItem('currentSnapshotId') as string
    const formRef = useRef(null)
    const [profileData, setProfileData] = useState<ProfileDataType>({
        agentFlag: null,
        tradeName: "",
        contactName: "",
        contactPosition: "",
        phoneNumber: "",
        phoneExtension: "",
        email: "",
        partialIncomeMonth: "",
        fiscalYearEndDate: "",
        ownerOccupiedFlag: null,
        acknowledgementFlag: null,
        analysisFlag: null,
        analysisReviewedBy: "",
        analysisReviewedDate: "",
    })
    const [ownerOccupiedError, setOwnerOccupiedError] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fiscalYearEndDateError, setFiscalYearEndDateError] = useState("");

    useEffect(() => {
        if (!profileDetails.loading &&
            (!profileDetails.success || profileDetails.snapshotId !== currentSnapshotId)) {
            dispatch({ type: RESET_GET_PROFILE_DETAILS })
            dispatch(getProfileDetails(currentSnapshotId))
        }
    }, [currentSnapshotId, dispatch, profileDetails])

    useEffect(() => {
        if (profileDetails.data && profileDetails.snapshotId === currentSnapshotId
            && !profileDetails.loading) {
            setProfileData(profileDetails.data)
            console.log("profileData", profileDetails.data)
        }
    }, [currentSnapshotId, dispatch, profileDetails])

    useEffect(() => {
        if (saveData.saving && formRef.current) {
            (formRef.current as HTMLFormElement).requestSubmit();
        }
    }, [dispatch, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileDetails(currentSnapshotId))
        }
    }, [currentSnapshotId, dispatch, saveResultState.status]);

    const dropdownOptions = [
        { value: "", text: "" },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" },
        { value: "11", text: "11" }
    ]

    const onFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLInputElement | HTMLSelectElement;
        target.value = target.value.trim();
        const targetName = target.name as string;
        if (targetName === "ownerOccupiedFlag") {
            setProfileData({ ...profileData, [targetName]: (target.value === "true" ? true : (target.value === "false" ? false : null)) });
        } else {
            setProfileData({ ...profileData, [targetName]: target.value });
        }
    }

    // const validate = (targetName: string, targetValue: string) => {
    //     if (isInternalUser && targetName !== "fiscalYearEndDate") {
    //         return true;
    //     }

    //     switch (targetName) {
    //         case "ownerOccupiedFlag":
    //             return validateOwnerOccupied(targetValue);
    //         // case "fiscalYearEndDate":
    //         //     return validateFiscalYearEndDate(targetValue);
    //         default:
    //             return true;
    //     }
    // }
    const validate = (targetName: string) => {
        if (isInternalUser && targetName !== "fiscalYearEndDate") {
            return true
        }

        switch (targetName) {
            case "ownerOccupiedFlag":
                return validateOwnerOccupied(profileData.ownerOccupiedFlag)
            // case "fiscalYearEndDate":
            //     return validateFiscalYearEndDate(profileData.fiscalYearEndDate)
            default:
                return true;
        }
    }

    const validateOwnerOccupied = (value: boolean | null) => {
        if (value === true || value === false) {
            setOwnerOccupiedError("");
            return true;
        } else {
            setOwnerOccupiedError(t('validation.ownerOccupiedRequired') as string);
            return false;
        }
    }

    // const validateFiscalYearEndDate = (value: string) => {
    //     if (value) {
    //         setFiscalYearEndDateError("");
    //         return true;
    //     } else {
    //         setFiscalYearEndDateError(t('validation.fiscalYearEndDateRequired') as string);
    //         return false;
    //     }
    // }

    const handleDateChange = (date: Date | null | undefined, name: string) => {
        if (typeof name !== "string") {
            return;
        }
        try {
            const dateValue = (date ? format(date, "yyyy-MM-dd") : "");
            setProfileData({ ...profileData, [name]: dateValue });
            // if (submitAttempted) {
            //     validateFiscalYearEndDate(dateValue);
            // }
        }
        catch (e) {
            console.log(e)
        }
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(resetPutProfileDetails());
        // const data: FormData = new FormData(e.target as HTMLFormElement);
        // const formValues = Object.fromEntries(data.entries()) as Record<string, unknown>;
        // if (showOwnerOccupied && !("ownerOccupiedFlag" in formValues)) {
        //     formValues["ownerOccupiedFlag"] = null;
        // }
        const validationFields = ["fiscalYearEndDate"]
        if (showOwnerOccupied) {
            validationFields.push("ownerOccupiedFlag")
        }
        let valid = false
        validationFields.forEach((field) => {
            valid = validate(field)
        }
        )
        if (valid) {
            dispatch(resetPutProfileDetails());
            dispatch(putProfileDetails(profileOverview.profile.profile_type_code, currentSnapshotId, profileData));
        } else {
            toast.warn(t("toastMessage.validationFailed") as string);
            dispatch(resetSaving());
            dispatch(setNextActiveMenuItem(initialMenuItem));
        }
    }

    useEffect(() => {
        if (showOwnerOccupied) {
            const menuListWithoutCommercialApartment = structuredClone(menuState.menu).filter((menu) => menu.name !== "commercial" && menu.name !== "apartment")
            if (profileData.ownerOccupiedFlag) {
                dispatch(setMenu(menuListWithoutCommercialApartment))
            } else {
                const summaryIndex = menuListWithoutCommercialApartment.findIndex((menu) => menu.name === "summaryReview")
                const newMenuList = menuListWithoutCommercialApartment.slice(0, summaryIndex)
                    .concat(structuredClone(BIPCommercialApartment))
                    .concat(menuListWithoutCommercialApartment.slice(summaryIndex))
                dispatch(setMenu([...newMenuList]))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileData.ownerOccupiedFlag, showOwnerOccupied])

    return (
        <>
            <div className='contact-info-header'></div>
            <form id="contact-form" className="contact-form" onSubmit={(e) => submit(e)} onChange={(e) => onFormChange(e)} ref={formRef}>
                <table className='contact-info-table'>
                    <tbody>
                        {
                            showOwnerOccupied &&
                            <tr>
                                <td colSpan={2}>
                                    <label><Asterisk /> {t("occupied")}</label>
                                </td>
                                <td>
                                    <span className={'side-by-side contact-owner-occupied-container' + (ownerOccupiedError ? ' radio-error' : '')}>
                                        <RadioButton name="ownerOccupiedFlag" value="true" id='contact-occupied-yes' text={t("yes")} textPosition='right'
                                            currentValue={profileData.ownerOccupiedFlag === null ? "" : profileData.ownerOccupiedFlag}
                                        />
                                        <RadioButton name="ownerOccupiedFlag" value="false" id='contact-occupied-no' text={t("no")} textPosition='right'
                                            currentValue={profileData.ownerOccupiedFlag === null ? "" : profileData.ownerOccupiedFlag}
                                        />
                                    </span>
                                    {ownerOccupiedError ? <div className="input-error-message">{ownerOccupiedError}</div> : ""}
                                </td>
                            </tr>
                        }
                        <tr>
                            <td colSpan={2}>
                                <label htmlFor='contact-fiscal-year-end'><Asterisk /> {t("fiscal")}</label>
                            </td>
                            <td>
                                <DatePicker id='contact-fiscal-year-end' className='contact-fiscal-year-end' name='fiscalYearEndDate'
                                    position='bottom' value={profileData.fiscalYearEndDate} minDate={parseISO("1990-01-01")} maxDate={new Date()}
                                    errorMessage={fiscalYearEndDateError} onChange={handleDateChange} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <label htmlFor='contact-partial-year'>{t("partialYear")}</label>
                            </td>
                            <td>
                                <Dropdown id='contact-partial-year' className='contact-partial-year' name='partialIncomeMonth' options={dropdownOptions}
                                    value={profileData.partialIncomeMonth ?? ""} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

