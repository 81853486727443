import React from 'react';
import { parseISO } from 'date-fns';
import Asterisk from '../common/Asterisk/Asterisk';
import DatePicker from '../common/DatePicker/DatePicker';
import "./CommercialUnit.css";

interface CommercialUnitDatePickerFieldProps {
    formData: Record<string, unknown>,
    name: string,
    onChange: (date: Date | null | undefined, name: string) => void,
    asterisk?: boolean,
    maxDate?: string | undefined,
    minDate?: string | undefined,
    heading?: string,
    title?: string,
    errors: Record<string, string>,
    id?: string,
    disabled?: boolean
}

export default function CommercialUnitDatePickerField(props: CommercialUnitDatePickerFieldProps): JSX.Element {

    return (
        props.heading ? 
        <>
            <tr>
                <td className='label-cell'>
                    {props.heading} {props.asterisk && <Asterisk />}
                </td>
            </tr>
            <tr>
                <td>
                    <DatePicker name={props.name} id={props.id ?? props.name}
                        date={props.formData[props.name] as string | undefined}
                        onChange={props.onChange}
                        className='border unit-input'
                        title={props.title}
                        position='right'
                        errorMessage={props.errors[props.name]}
                        maxDate={props.maxDate ? parseISO(props.maxDate) : undefined}
                        minDate={props.minDate ? parseISO(props.minDate) : undefined}
                        disabled={props.disabled}
                    />
                </td>
            </tr>
        </> :
        <DatePicker name={props.name} id={props.id ?? props.name}
            date={props.formData[props.name] as string | undefined}
            onChange={props.onChange}
            className='border unit-input'
            title={props.title}
            position='right'
            errorMessage={props.errors[props.name]}
            maxDate={props.maxDate ? parseISO(props.maxDate) : undefined}
            minDate={props.minDate ? parseISO(props.minDate) : undefined}
        />
    );
}
