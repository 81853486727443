/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Input from '../../../components/common/Input/Input';
import Asterisk from '../../../components/common/Asterisk/Asterisk';
import RadioButton from '../../../components/common/RadioGroup/RadioButton';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import "./ContactInfo.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import MenuState from '../../../types/menu/MenuState';
import ProfileDataType from '../../../types/profile/ProfileData';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { resetSaving } from '../../../redux/actions/saveData/saveData';
import { getProfileDetails, putProfileDetails, resetPutProfileDetails } from '../../../redux/actions/profile/profile';
import { setMenu, setNextActiveMenuItem } from '../../../redux/actions/menu/menu';
import { initialMenuItem } from '../../../redux/reducers/getMenu/getMenuReducer';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { RESET_GET_PROFILE_DETAILS } from '../../../redux/actionTypes/actionsTypes';

export default function ContactInfo(): JSX.Element {
    const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const { t } = useTranslation('contact_info');  
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const profileType = profileOverview.profile.profile_type_code;
    const isInternalUser = localStorage.getItem('isInternalUser') ? true : false;
    const currentSnapshotId = localStorage.getItem('currentSnapshotId') as string
    const [submitAttempted, setSubmitAttempted] = useState(false);

     const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    // eslint-disable-next-line
    const [agentFlagError, setAgentFlagError] = useState("");

    const [profileData, setProfileData] = useState<ProfileDataType>({
        tradeName: "",
        contactName: "",
        contactPosition: "",
        phoneNumber: "",
        phoneExtension: "",
        partialIncomeMonth: "",
        email: "",
        fiscalYearEndDate: "",
        ownerOccupiedFlag: null,
        acknowledgementFlag: null,
        agentFlag: null,
        analysisFlag: null,
        analysisReviewedBy: "",
        analysisReviewedDate: "",
    });

    useEffect(() => {
        if (!profileDetails.loading &&
            (!profileDetails.success || profileDetails.snapshotId !== currentSnapshotId)) {
            dispatch({ type: RESET_GET_PROFILE_DETAILS })
            dispatch(getProfileDetails(currentSnapshotId));
        }
    }, [currentSnapshotId, dispatch, profileDetails]);

    useEffect(() => {
        if (profileDetails.data && profileDetails.snapshotId === currentSnapshotId
            && !profileDetails.loading) {
            setProfileData(profileDetails.data);
        }
    }, [currentSnapshotId, dispatch, profileDetails]);

    useEffect(() => {
        if (saveData.saving && formRef.current) {
            (formRef.current as HTMLFormElement).requestSubmit();
        }
    }, [saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getProfileDetails(currentSnapshotId))
        }
    }, [saveResultState, profileOverview.profile, dispatch, currentSnapshotId]);

    useEffect(() => {
        const showOwnerOccupied = ["402", "403", "409", "431", "433", "434", "435", "436"].includes(localStorage.getItem("propertyCode") as string)
        if (showOwnerOccupied && profileData.ownerOccupiedFlag) {
            const menuListWithoutCommercialApartment = structuredClone(menuState.menu).filter((menu) => menu.name !== "commercial" && menu.name !== "apartment")
            dispatch(setMenu(menuListWithoutCommercialApartment))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileData.ownerOccupiedFlag])

    const analysisOptions = [
        {value: "", text: ""},
        {value: "Y", text: "Y"},
        {value: "N", text: "N"},
        {value: "X", text: "X"},
        {value: "F", text: "F"}
    ]

    const onFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        const target = e.target as HTMLInputElement | HTMLSelectElement;
        target.value = target.value.trim();
        const targetName = target.name as string;
        if (targetName === "agentFlag") {
            setProfileData({...profileData, [targetName]: (target.value === "true" ? true : (target.value === "false" ? false : null))});
        } else if (targetName === "ownerOccupiedFlag"){
            setProfileData({...profileData, [targetName]: (target.value === "true" ? true : (target.value === "false" ? false : null))});
        } else if (targetName === "analysisFlag") {
            const analysisReviewedBy = localStorage.getItem("username") ?? ""
            const analysisReviewedDate = (new Date().toISOString())
            
            setProfileData({...profileData,
                [targetName]: target.value,
                "analysisReviewedBy": analysisReviewedBy,
                "analysisReviewedDate": analysisReviewedDate
            })
        } else {
            setProfileData({...profileData, [targetName]: target.value});
        }
        if (submitAttempted) {
            validate(targetName, target.value);
        }
    }

    const validate = (targetName: string, targetValue: string) => {
        if (isInternalUser) {
            return true;
        }

        switch (targetName) {
            case "contactName":
                return validateName(targetValue);
            case "phoneNumber":
                return validatePhoneNumber(targetValue);
            case "email":
                return validateEmail(targetValue);
            // case "fiscalYearEndDate":
            //     return validateFiscalYearEndDate(targetValue);
            case "agentFlag":
                return validateAgentFlag(targetValue);
            default:
                return true;
        }
    }

    const validateAgentFlag = (value: string) => {
        if (value !== "true" && value !== "false") {
            setAgentFlagError("Validation of agent flag failed");
            return false;
        } else {
            setAgentFlagError("");
            return true;
        }
    }

    const validateName = (value: string) => {
        if (value === "") {
            setNameError(t('validation.nameRequired') as string);
            return false;
        } else {
            setNameError("");
            return true;
        }
    }

    const validatePhoneNumber = (value: string) => {
        const numberRegex = /^[0-9]+$/;
        if (value === "" || value.length !== 10 || !numberRegex.test(value)) {
            setPhoneError(t('validation.phoneRequired') as string);
            return false;
        } else {
            setPhoneError("");
            return true;
        }
    }

    const validateEmail = (value: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        if (value === "" || value.length >= 100 || !emailRegex.test(value)) {
            setEmailError(t('validation.emailRequired') as string);
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(resetPutProfileDetails());
        setSubmitAttempted(true);
        const data: FormData = new FormData(e.target as HTMLFormElement);
        const formValues = Object.fromEntries(data.entries()) as Record<string, unknown>;
        if (!("agentFlag" in formValues)) {
            formValues["agentFlag"] = null;
        }
        let valid = false
        for (const key in formValues) {
            const value = formValues[key] as string;
            valid = validate(key, value);
        }
        // dispatch(setValid(isDataValid));
        if (valid) {
            dispatch(resetPutProfileDetails());
            dispatch(putProfileDetails(profileOverview.profile.profile_type_code, currentSnapshotId, profileData));
        } else {
            toast.warn(t("toastMessage.validationFailed") as string); 
            dispatch(resetSaving());
            dispatch(setNextActiveMenuItem(initialMenuItem));
        }
    }

    const buildInputRows = (profileType: string) : JSX.Element[] => {
        const tableCells = [];
        if (["HTL", "LLE", "MTL", "RHO", "GLF", "LTC"].includes(profileType)){
            tableCells.push(<td key="contact-cell-1">
                <div>
                    <label className='top-label' htmlFor='contact-trade-name'>
                    {profileType === "LLE" ? t("property") : 
                        profileType === "RHO" ? t("retirement") : 
                        profileType === "LTC" ? t("ltc")  : 
                        profileType === "GLF" ? t("golf")  : 
                        profileType === "HTL" ? t("hotel") : t("motel") }</label>
                </div>
                <div>
                    <Input name='tradeName' id='contact-trade-name' type='text' 
                    className='contact-trade-name full-width-field' value={profileData.tradeName}
                    label={profileType === "LLE" ? t("property") : 
                        profileType === "RHO" ? t("retirement")  : 
                        profileType === "LTC" ? t("ltc")  : 
                        profileType === "GLF" ? t("golf")  : 
                        profileType === "HTL" ? t("hotel") : t("motel") }/>
                </div> 
            </td>);
        }
        tableCells.push(<td key="contact-cell-2">
            <div>
                <label className='top-label' htmlFor='contact-name'><Asterisk /> {["HTL", "LLE", "RHO", "LTC", "GLF"].includes(profileType) ? 
                    t("contact")  : profileType === "MTL" ? t("manager") : t("name")}</label>
            </div>
            <div>
                <Input name='contactName' id='contact-name' type='text' className='contact-name full-width-field' 
                    label={["HTL", "LLE", "RHO", "LTC", "GLF"].includes(profileType) ? 
                    t("contact")  : profileType === "MTL" ? t("manager") : t("name")} value={profileData.contactName}
                    errorMessage={nameError} hideErrorCross={true} noTimer={true} isError={nameError !== ""} />
            </div>
        </td>);
        if (profileType === "HTL") {
            tableCells.push(<td key="contact-cell-3">
                <div>
                    <label className='top-label' htmlFor='contact-position'>{t("position")}</label>
                </div>
                <div>
                    <Input name='contactPosition' id='contact-position' type='text' className='contact-position full-width-field' 
                    label={t("position")} value={profileData.contactPosition}/>
                </div>
            </td>);
        }
        tableCells.push(<td key="contact-cell-4">
            <div>
                <label className='top-label' htmlFor='contact-phone'><Asterisk /> {t("telephone")}</label>
            </div>
            <div className='telephone-field'>
                <div className='contact-phone-container'>
                    <Input name='phoneNumber' id='contact-phone' type='text' className='contact-phone' label={t("telephone")} value={profileData.phoneNumber}
                    errorMessage={phoneError} hideErrorCross={true} noTimer={true} isError={phoneError !== ""} />
                </div>
                <div className='contact-ext-container'>
                    <Input name='phoneExtension' id='contact-ext' type='text' className='contact-ext' value={profileData.phoneExtension}/>
                </div>
            </div>
        </td>);
        tableCells.push(<td key="contact-cell-5">
            <div>
                <label className='top-label' htmlFor='contact-email'><Asterisk /> {t("email")}</label>
            </div>
            <div>
                <Input name='email' id='contact-email' type='text' className='contact-email' label={t("email")} value={profileData.email}
                errorMessage={emailError} hideErrorCross={true} noTimer={true} isError={emailError !== ""} /> 
            </div>
        </td>);
        const tableRows: JSX.Element[] = [];
        const tempCells: JSX.Element[] = [];
        tableCells.forEach((cell, index) => {
            tempCells.push(cell);
            if (tempCells.length === 3 || index === tableCells.length - 1) {
                tableRows.push(<tr className='top-aligned-row' key={`contact-row-${index}`}>
                    {tempCells.map((cell) => (cell))}
                </tr>)
                tempCells.splice(0, tempCells.length); 
            }
        });
       
        return tableRows;
    }

    return (
        <>
            <div className='contact-info-header'><p className='strong'>{t("heading") }</p></div>
                <form id="contact-form" className="contact-form" onSubmit={(e) => submit(e)} onChange={(e) => onFormChange(e)} ref={formRef}>
                    <table className='contact-info-table'>
                        <tbody>
                            {isInternalUser && <tr>
                                <td colSpan={2}/><td>
                                    <label className='contact-analysis-label' htmlFor='contact-analysis'>{t("analysis") }</label>
                                    <Dropdown id='contact-analysis' className='contact-analysis' name='analysisFlag' options={analysisOptions} value={profileData.analysisFlag ?? undefined} />
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan={3}>
                                    <div className={'side-by-side contact-owner-agent-container' + (agentFlagError ? ' radio-error' : '')}>
                                        <RadioButton id='contact-owner' name='agentFlag' text={t("owner")} textPosition='right' 
                                        value="false" currentValue={profileData.agentFlag === null ? "" : profileData.agentFlag} />
                                        <RadioButton id='contact-agent' name='agentFlag' text={t("agent")} textPosition='right'
                                        value="true" currentValue={profileData.agentFlag === null ? "" : profileData.agentFlag} />
                                    </div>
                                    {agentFlagError ? <div className="input-error-message">{agentFlagError}</div> : ""}
                                </td>
                            </tr>
                            {buildInputRows(profileType).map((row) => (row))}
                        </tbody>
                    </table>
                </form>
        </>
    );
}
