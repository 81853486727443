import {
    API_REQUEST,
    GET_INCOME_UNIT_BY_ID,
    DELETE_INCOME_UNIT_BY_ID,
    RESET_GET_INCOME_BY_ID,
    RESET_DELETE_INCOME_BY_ID,
    GET_TENANT_SUMMARY_BY_SNAPSHOT_ID,
    GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID,
    RESET_GET_TENANT_SUMMARY_BY_SNAPSHOT_ID,
    PUT_INCOME_UNIT_BY_ID,
    RESET_PUT_INCOME_UNIT,
    POST_INCOME_UNIT,
    RESET_POST_INCOME_UNIT,
    GET_INDEPENDENT_LIVING_UNITS_BY_SNAPSHOT_ID,
    GET_ASSISTED_LIVING_UNITS_BY_SNAPSHOT_ID,
    GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID,
    GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID,
    RESET_GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID
} from "../../actionTypes/actionsTypes";
import { AppAction, AsyncActionType, LocalAction } from "../../types";

export const getTenantSummaryBySnapshotId = (snapshotId: number | string, unitType: string, isPrevious = false): AppAction => {
    let unitTypeAction: AsyncActionType = isPrevious ? GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID : GET_TENANT_SUMMARY_BY_SNAPSHOT_ID
    switch(unitType) {
        case '4':
          unitTypeAction = GET_INDEPENDENT_LIVING_UNITS_BY_SNAPSHOT_ID;
          break;
        case '5':
          unitTypeAction = GET_ASSISTED_LIVING_UNITS_BY_SNAPSHOT_ID;
          break;
        case '6':
          unitTypeAction = GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID;
          break;
      }
    return {
        type: API_REQUEST,
        payload: {
            url: `/income/tenant-summary/${snapshotId}/${unitType}`,
            next: unitTypeAction,
            method: 'GET',
            authorization: true
        }
    }
};

export const resetGetTenantSummaryBySnapshotId = (): LocalAction => ({
    type: RESET_GET_TENANT_SUMMARY_BY_SNAPSHOT_ID
});

export const resetGetPreviousTenantSummaryBySnapshotId = (): LocalAction => ({
    type: RESET_GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID
});

export const getApartmentTenantSummaryBySnapshotId = (snapshotId: number | string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/tenant-summary/${snapshotId}/3`,
        next: GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID,
        method: 'GET',
        authorization: true
    }
});

export const putIncomeUnit = (snapshotId: number | string, unit: Record<string, unknown>) => ({
    type: API_REQUEST,
    payload: {
      url: `/income/${snapshotId}/save-unit/${unit['tenantId']}`,
      data: { "unit": unit },
      next: PUT_INCOME_UNIT_BY_ID,
      method: 'PUT',
      authorization: true
    }
})

export const resetPutIncomeUnit = (): LocalAction => ({
    type: RESET_PUT_INCOME_UNIT
})


export const postIncomeUnit = (snapshotId: number | string, unit: Record<string, unknown>) => ({
    type: API_REQUEST,
    payload: {
      url: `/income/${snapshotId}/add-unit`,
      data: { "unit": unit },
      next: POST_INCOME_UNIT,
      method: 'POST',
      authorization: true
    }
})

export const postMultipleIncomeUnits = (snapshotId: number | string, units: Record<string, unknown> | Record<string, unknown>[], type: string) => ({
    type: API_REQUEST,
    payload: {
      url: `/income/${snapshotId}/add-units`,
      data: { "units": units, "type": type },
      next: POST_INCOME_UNIT,
      method: 'POST',
      authorization: true
    }
})

export const resetPostIncomeUnit = (): LocalAction => ({
    type: RESET_POST_INCOME_UNIT
})

// Manage unit income action
export const getIncomeUnitById = (snapshotId: string | number, unitId: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/${snapshotId}/get-unit/${unitId}`,
        next: GET_INCOME_UNIT_BY_ID,
        method: 'GET',
        authorization: true
    }
});

export const resetGetIncomeUnitById = (): LocalAction => ({
    type: RESET_GET_INCOME_BY_ID
});


export const deleteIncomeUnitById = (snapshotId: string | number, unitId: string): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/${snapshotId}/unit/${unitId}`,
        next: DELETE_INCOME_UNIT_BY_ID,
        method: 'DELETE',
        authorization: true
    }
});

export const resetDeleteIncomeUnitById = (): LocalAction => ({
    type: RESET_DELETE_INCOME_BY_ID
});