import React, { useEffect, useState } from 'react';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import "./GuidedExperienceWizard.css";
import { getTenantSummaryBySnapshotId, postMultipleIncomeUnits, resetGetPreviousTenantSummaryBySnapshotId } from '../../redux/actions/incomeUnit/incomeUnit';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import { createUnitState } from '../../redux/reducers/getIncomeUnit/createUnitReducer';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Button from '../common/Button/Button';
import FloorWizard from './FloorWizard';
import BasicUnitInfoWizard from './BasicUnitInfoWizard';
import Modal from '../common/Modal/Modal';
import LeaseStatusWizard from './LeaseStatusWizard';
import LeaseTermWizard from './LeaseTermWizard';
import VacancyStartWizard from './VacancyStartWizard';
import UnitUseWizard from './UnitUseWizard';
import LeaseRateWizard from './LeaseRateWizard';
import LeaseTypeWizard from './LeaseTypeWizard';
import OperatingExpensesWizard from './OperatingExpensesWizard';
import FreeMonthWizard from './FreeMonthWizard';
import LandLeaseWizard from './LandLeaseWizard';
import StepUpWizard from './StepUpWizard';
import StepUpDetailsWizard from './StepUpDetailsWizard';
import ConfirmImportWizard from './ConfirmImportWizard';
import { GetPreviousTenantSummaryState } from '../../redux/reducers/getIncomeUnit/getPreviousTenantSummaryReducer';
import NotExpiredLeaseWizard from './NotExpiredLeaseWizard';
import UpdateImportWizard from './UpdateImportWizard';
import FixEndDateWizard from './FixEndDateWizard';
import SummaryWizard from './SummaryWizard';
import { toast } from 'react-toastify';


interface GuidedExperienceWizardProps {
    snapshotId: number;
    onCancel: () => void;
    importSnapshotId: null | number;
}

export default function GuidedExperienceWizard(props: GuidedExperienceWizardProps): JSX.Element {
    const { t } = useTranslation("guided_wizard");
    const dispatch = useDispatch();
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const postIncomeUnit: createUnitState = useSelector((state: RootState) => state.incomeUnitReducer.postIncomeUnit);
    const getPreviousTenantSummaryState: GetPreviousTenantSummaryState = useSelector((state: RootState) => state.incomeUnitReducer.getPreviousTenentSummary);
    const [submitted, setSubmitted] = useState(false);
    const [tenantList, setTenantList] = useState<Record<string, unknown>[]>([]);
    const [confirmedImport, setConfirmedImport] = useState<undefined | boolean>(props.importSnapshotId ? undefined : false);

    // This tracks the number of units on each floor level
    const [wizardNumber, setWizardNumber] = useState((elsLookupsState.dropdowns.floorLevel as Record<string, unknown>[]).filter((floorLevelOption) => 
        floorLevelOption["activeFlag"] === "Y").reduce((wizardNumberRecord, floorLevelOption)=> ({[floorLevelOption["unitLevelCode"] as string]: 
            (tenantList.filter((tenant) => tenant["floorLevel"] as string === floorLevelOption["unitLevelCode"] as string)).length, 
            ...wizardNumberRecord}),{}));
    // This is the wizard number before the user changes the number of units on each floor level
    const oldWizardNumber = (elsLookupsState.dropdowns.floorLevel as Record<string, unknown>[]).filter((floorLevelOption) => 
        floorLevelOption["activeFlag"] === "Y").reduce((wizardNumberRecord, floorLevelOption)=> ({[floorLevelOption["unitLevelCode"] as string]: 
            (tenantList.filter((tenant) => tenant["floorLevel"] as string === floorLevelOption["unitLevelCode"] as string)).length, 
            ...wizardNumberRecord}),{});
    const simplifiedFloorNameLookup = ((elsLookupsState.dropdowns.floorLevel as Record<string, unknown>[]).filter((floorLevelOption) => 
        floorLevelOption["activeFlag"] === "Y").reduce((simplifiedLookup, floorLevelOption)=> ({[floorLevelOption["unitLevelCode"] as string]: 
            floorLevelOption["unitLevelDescription"] as string, 
            ...simplifiedLookup}),{"undefined": ""}));
    const simplifiedLeaseStatusLookup = ((elsLookupsState.dropdowns.leaseStatusCodeOptions as Record<string, unknown>[]).reduce((simplifiedLookup, leaseStatus) => 
        ({[leaseStatus["value"] as string]: leaseStatus["text"] as string, ...simplifiedLookup}),{"undefined": ""}));
    const simplifiedLeaseTypeLookup = ((elsLookupsState.dropdowns.leaseTypeCodeOptions as Record<string, unknown>[]).reduce((simplifiedLookup, leaseType) => 
        ({[leaseType["value"] as string]: leaseType["text"] as string, ...simplifiedLookup}),{"undefined": ""}));
    const simplifiedUnitSubtypeLookup = ((elsLookupsState.dropdowns.unitSubType1 as Record<string, unknown>[]).reduce((simplifiedLookup, unitSubtype) => 
        ({[unitSubtype["unitSubtypeCode"] as string]: unitSubtype["unitSubtypeDescription"] as string, ...simplifiedLookup}),{"undefined": ""}));
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [proceed, setProceed] = useState(false);
    const [wizardUpdated, setWizardUpdated] = useState(false); 
    const [closingWizard, setClosingWizard] = useState(false);
    const [unitUseForAll, setUnitUseForAll] = useState("");
    const [isSameType, setIsSameType] = useState<boolean | undefined>(undefined);
    const [leaseTypeForAll, setLeaseTypeForAll] = useState("");
    const [leaseTypeSameType, setLeaseTypeSameType] = useState<boolean | undefined>(undefined);
    const [hasFreeMonth, setHasFreeMonth] = useState<boolean | undefined>(undefined);
    const [hasLandLease, setHasLandLease] = useState<boolean | undefined>(undefined);
    const [hasStepUp, setHasStepUp] = useState<boolean | undefined>(undefined);
    const [stepUpArr, setStepUpArr] = useState<number[]>([]);
    const [fetchedPreviousUnits, setFetchPreviousUnits] = useState(false);
    const [assignedPreviousUnits, setAssignedPreviousUnits] = useState(false);
    const [confirmedArr, setConfirmedArr] = useState<number[]>([]);
    const [confirmedTenants, setConfirmedTenants] = useState<Record<string, unknown>[]>([]);
    const [confirmedArrSeparated, setConfirmedArrSeparated] = useState(false);

    const updateWizardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name.substring(13);
        const numberOnlyRegex = /^[0-9]+$/;
        if (numberOnlyRegex.test(value)) {
            const newWizardNumber = {
                ...wizardNumber,
                [name]: parseInt(value)
            }
            setWizardNumber(newWizardNumber);
            setWizardUpdated(true);
        } else if (value === "") {
            const newWizardNumber = {
                ...wizardNumber,
                [name]: 0
            }
            setWizardNumber(newWizardNumber);
            setWizardUpdated(true);
            e.target.value = "0";
        }else if (name in wizardNumber) {
            e.target.value = (wizardNumber[name] as number).toString();
        }
    }

    const updateTenantListInfo = (e: React.ChangeEvent<Element> | string | undefined, index: number, fieldName: string, isNumber = false, isCheckbox = false) => {
        const newTList = [...tenantList];
        let value;
        if (e && isCheckbox){
            value = ((e as React.ChangeEvent<Element>).target as HTMLInputElement).checked;
        } else if (typeof e === "string") {
            value = e;
        } else if (e === undefined) {
            value = undefined;
        } else {
            value = (e.target as HTMLInputElement).value;
        }
        if (isNumber) {
            if (value === undefined) {
                newTList[index][fieldName] = value;
            } else {
                newTList[index][fieldName] = parseFloat(value as string);
            }
        } else {
            newTList[index][fieldName] = value;
        }
        setTenantList(newTList);
    }

    const removeUnit = (index: number) => {
        const newTList = [...tenantList];
        newTList.splice(index, 1)
        setTenantList(newTList);
    }

    const previousWizardPage = () => {
        const pageElements = (confirmedImport === true ? importWizardElements : orderedWizardElements)
        if (currentWizardPage > 0) {
            if ((pageElements[currentWizardPage - 1].key === "wizard-vacancy-start" && tenantList.filter((tenant) => tenant["leaseStatusCode"] === "1").length === 0)
                || (pageElements[currentWizardPage - 1].key === "wizard-step-up-details" && stepUpArr.length === 0)) {
                setCurrentWizardPage(currentWizardPage - 2);
            } else {
                setCurrentWizardPage(currentWizardPage - 1);
            }
            
        }
    }

    const nextWizardPage = () => {
        const pageElements = (confirmedImport === true ? importWizardElements : orderedWizardElements)
        if (currentWizardPage < ((pageElements.length) - 1)) {
            if (wizardUpdated) {
                const keys = Object.keys(wizardNumber);
                let warningFloors = "";
                let totalUnits = 0;
                keys.forEach((key) => {
                    if ((wizardNumber[key] as number) < (oldWizardNumber[key] as number)) {
                        warningFloors += (warningFloors === "" ? "" : " ,") + simplifiedFloorNameLookup[key];
                    } 
                    totalUnits += (wizardNumber[key] as number);
                });
                if (totalUnits === 0) {
                    return;
                }
                if (warningFloors !== "") {
                    setWarningMessage(warningFloors + t("floor-wizard.floor-warning"));
                    setShowWarningModal(true);
                    return;
                } else {
                    setProceed(true);
                }
            }
            if (tenantList.length > 0) {
                setCurrentPage(1);
                if (pageElements[currentWizardPage].key === "wizard-not-expired-lease") {
                    setWarningMessage(t("not-expired-lease-wizard.not-expired-lease-warning"));
                    setShowWarningModal(true);
                    return;
                }
                if ((pageElements[currentWizardPage + 1].key === "wizard-vacancy-start" && tenantList.filter((tenant) => tenant["leaseStatusCode"] === "1").length === 0)
                    || (pageElements[currentWizardPage + 1].key === "wizard-step-up-details" && stepUpArr.length === 0)
                    || (pageElements[currentWizardPage + 1].key === "wizard-not-expired-lease" && tenantList.filter((tenant) => ((tenant["endDate"] && new Date(tenant["endDate"] as string) >=  new Date()) || tenant["leaseStatusCode"] === "1" || tenant["leaseStatusCode"] === "5")).length === 0)) {
                        setCurrentWizardPage(currentWizardPage + 2);
                } else {
                    setCurrentWizardPage(currentWizardPage + 1);
                }
            }
        }
    }
    const commonWizardElements = [
        <LeaseStatusWizard key="wizard-lease-status" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage} leaseStatusLookup={elsLookupsState.dropdowns.leaseStatusCodeOptions as Record<string, unknown>[]}/>,
        <LandLeaseWizard key="wizard-land-lease" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            hasLandLease={hasLandLease} setHasLandLease={setHasLandLease} totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>,
        <LeaseTermWizard key="wizard-lease-term" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage} leaseStatusLookup={simplifiedLeaseStatusLookup}/>,
        <VacancyStartWizard key="wizard-vacancy-start" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} leaseStatusLookup={simplifiedLeaseStatusLookup}/>,
        <UnitUseWizard key="wizard-unit-use" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} unitUseForAll={unitUseForAll} 
            setUnitUseForAll={setUnitUseForAll} isSameType={isSameType} setIsSameType={setIsSameType} totalPages={totalPages} currentPage={currentPage} 
            changePage={setCurrentPage} unitUseOptions={elsLookupsState.dropdowns.unitSubType1 as Record<string, unknown>[]}/>,
            <FreeMonthWizard key="wizard-free-month" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            hasFreeMonth={hasFreeMonth} setHasFreeMont={setHasFreeMonth} totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>,
        <LeaseRateWizard key="wizard-lease-rate" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage} unitSubtypeLookup={simplifiedUnitSubtypeLookup}/>,
        <LeaseTypeWizard key="wizard-lease-type" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} leaseTypeForAll={leaseTypeForAll} 
            setLeaseTypeForAll={setLeaseTypeForAll} isSameType={leaseTypeSameType} setIsSameType={setLeaseTypeSameType} totalPages={totalPages} currentPage={currentPage} 
            changePage={setCurrentPage} leaseTypeOptions={elsLookupsState.dropdowns.leaseTypeCodeOptions as Record<string, unknown>[]} unitSubtypeLookup={simplifiedUnitSubtypeLookup}/>,
        <OperatingExpensesWizard key="wizard-operating-expenses" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>,
        <StepUpWizard key="wizard-step-up" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            hasStepUp={hasStepUp} setHasStepUp={setHasStepUp} stepUpArr={stepUpArr} setStepUpArr={setStepUpArr} totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>,
        <StepUpDetailsWizard key="wizard-step-up-details" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} stepUpArray={stepUpArr} />,
        <SummaryWizard key="wizard-summary" tenantList={tenantList} setTenantList ={setTenantList} stepUpArr={stepUpArr} setStepUpArr={setStepUpArr} confirmedTenants={confirmedTenants} setConifirmedList={setConfirmedTenants} floorDescriptionLookup={simplifiedFloorNameLookup} leaseStatusLookup={simplifiedLeaseStatusLookup} leaseTypeLookup={simplifiedLeaseTypeLookup} />
    ];
    const orderedWizardElements = [
        <FloorWizard key="wizard-floor" updateWizardFunction={updateWizardNumber} wizardNumber={wizardNumber} />,
        <BasicUnitInfoWizard key="wizard-basic-unit-info" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup}
            totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage}/>,
        ...commonWizardElements
    ];
    const importWizardElements = [
        <FixEndDateWizard key="wizard-fix-end-date" updateWizardFunction={updateTenantListInfo} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} leaseStatusLookup={simplifiedLeaseStatusLookup} 
        leaseTypeLookup={simplifiedLeaseTypeLookup} />,
        <NotExpiredLeaseWizard key="wizard-not-expired-lease" tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} leaseStatusLookup={simplifiedLeaseStatusLookup} 
        leaseTypeLookup={simplifiedLeaseTypeLookup} confirmedArr={confirmedArr} setConfirmedArr={setConfirmedArr} />,
        <UpdateImportWizard key="wizard-update-import" updateWizardFunction={updateTenantListInfo} removeUnit={removeUnit} tenantList={tenantList} floorDescriptionLookup={simplifiedFloorNameLookup} 
        leaseStatusLookup={simplifiedLeaseStatusLookup} confirmedArr={confirmedArr} setTenantList={setTenantList} setConfirmedTenants={setConfirmedTenants} setTotalPages={setTotalPages}
        totalPages={totalPages} currentPage={currentPage} changePage={setCurrentPage} setStepUpArr={setStepUpArr} confirmedArrSeparated={confirmedArrSeparated} setConfirmedArrSeparated={setConfirmedArrSeparated} />,
        ...commonWizardElements
    ]
    const [currentWizardPage, setCurrentWizardPage] = useState(0);  

    useEffect(() => {
        if (!postIncomeUnit.loading && submitted) {
            setSubmitted(false);
            props.onCancel();
        }
    // eslint-disable-next-line
    }, [postIncomeUnit, submitted]);

    useEffect(() => {
        if (!fetchedPreviousUnits && confirmedImport === true && !getPreviousTenantSummaryState.loading) {
            dispatch(resetGetPreviousTenantSummaryBySnapshotId());
            dispatch(getTenantSummaryBySnapshotId(props.importSnapshotId as number, "1", true))
            setFetchPreviousUnits(true);
        } 
    }, [confirmedImport, getPreviousTenantSummaryState.loading, fetchedPreviousUnits, dispatch, props.importSnapshotId]);

    useEffect(() => {
        if (!assignedPreviousUnits && fetchedPreviousUnits && confirmedImport === true && !getPreviousTenantSummaryState.loading) {
            if (getPreviousTenantSummaryState.success) {
                const summary = getPreviousTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false);
                if (summary && summary.length > 0) {
                    setTenantList(summary);
                    if (summary.length > 10) {
                        setTotalPages(Math.ceil(summary.length / 10));
                    } else {
                        setTotalPages(undefined);
                    }
                    if (summary.filter((tenant) => (
                        (["2", "3", "4"].includes(tenant["leaseStatusCode"] as string) && (!tenant["endDate"] || tenant["endDate"] === "")) 
                        || ((tenant["leaseStatusCode"] === "1" || tenant["leaseStatusCode"] === "5") && tenant["endDate"])
                    )).length === 0) {
                        if (summary.filter((tenant) => ((tenant["endDate"] && new Date(tenant["endDate"] as string) >=  new Date()) || tenant["leaseStatusCode"] === "1" || tenant["leaseStatusCode"] === "5")).length === 0){
                            setCurrentWizardPage(2);
                        } else {
                            setCurrentWizardPage(1);
                        }
                    }
                } else {
                    toast.warn(t("not-found") as string);
                    setConfirmedImport(false);
                }
            } else {
                toast.warn(t("not-found") as string);
                setConfirmedImport(false);
            }
            setAssignedPreviousUnits(true);
        }
    }, [getPreviousTenantSummaryState, currentWizardPage, importWizardElements.length, confirmedImport, assignedPreviousUnits, fetchedPreviousUnits, t]);

    useEffect(() => {
        if (proceed && !closingWizard) {
            if (wizardUpdated) {
                const keys = Object.keys(wizardNumber);
                const tList: Record<string, unknown>[] = [];
                keys.forEach((key) => {
                    let numbersToPush = 0;
                    if ((wizardNumber[key] as number) >= (oldWizardNumber[key] as number)) {
                        tList.push(...tenantList.filter((tenant) => tenant["floorLevel"] === key));
                        numbersToPush = (wizardNumber[key] as number) - (oldWizardNumber[key] as number);
                    } else {
                        numbersToPush = (wizardNumber[key] as number);
                    }
                    for (let i = 0; i < numbersToPush; i++ ) {
                        tList.push({
                            tenantName: undefined,
                            unitSubtypeCode: undefined,
                            leaseStatusCode: undefined,
                            startDate: undefined,
                            negotiatedDate: undefined,
                            endDate: undefined,
                            landLeaseFlag: undefined,
                            leaseTypeCode: undefined,
                            netLeasableArea: undefined,
                            tenantUnitNumber: undefined,
                            floorLevel: key,
                            includeInsuranceFlag: undefined,
                            includeManagementFlag: undefined,
                            includeMaintenanceFlag: undefined,
                            includeHydroFlag: undefined,
                            includeWaterFlag: undefined,
                            includeHvacFlag: undefined,
                            annualRent: undefined,
                            currentBaseRent: undefined,
                            propertyTaxRecovery: undefined,
                            operatingRecovery: undefined,
                            freeRentMonth: undefined,
                            tenantImprovement: undefined,
                            stepUpDate1: undefined,
                            stepUpRate1: undefined,
                            stepUpDate2: undefined,
                            stepUpRate2: undefined,
                            stepUpDate3: undefined,
                            stepUpRate3: undefined,
                            stepUpDate4: undefined,
                            stepUpRate4: undefined,
                            buildingIdNumber: undefined,
                            tenancyTypeCode: undefined,
                            designTypeCode: undefined,
                            variables: [],
                            analysisFlag: undefined,
                            priority: undefined,
                            linkFlag: undefined,
                            snapshotId: props.snapshotId,
                            tenantId: undefined,
                            unitTypeCode: "1"
                        });
                    }
                });
                setTenantList(tList);
                if (tList.length > 10) {
                    setTotalPages(Math.ceil(tList.length / 10));
                } else {
                    setTotalPages(undefined);
                }
                setWizardUpdated(false);
            }
            setCurrentPage(1);
            setWarningMessage("");
            setProceed(false);
            const pageElements = confirmedImport === true ? importWizardElements : orderedWizardElements
            if (currentWizardPage < (pageElements.length - 1)) {
                if (pageElements[currentWizardPage + 1].key === "wizard-update-import" && confirmedArr.length === tenantList.length && confirmedArr.length !== 0) {
                    setCurrentWizardPage(importWizardElements.length - 1);
                } else {
                    setCurrentWizardPage(currentWizardPage + 1);
                }
            }
            setShowWarningModal(false);
        }
        // eslint-disable-next-line
    }, [wizardUpdated, proceed]);

    useEffect(() => {
        if (closingWizard && proceed) {
            setClosingWizard(false);
            setProceed(false);
            setWarningMessage("");
            props.onCancel();
        }
    }, [closingWizard, proceed, props]);

    useEffect(() => {
        if (isSameType && unitUseForAll !== "") {
            tenantList.forEach((tenant) => (tenant["leaseStatusCode"] as string) === '1' ? tenant["unitSubtypeCode"] = undefined : tenant["unitSubtypeCode"] = unitUseForAll);
        }
        setTenantList([...tenantList]);
        // eslint-disable-next-line
    }, [isSameType, unitUseForAll]);

    useEffect(() => {
        if (leaseTypeSameType && leaseTypeForAll !== "") {
            tenantList.forEach((tenant) => (tenant["leaseStatusCode"] as string) === '1' ? tenant["leaseTypeCode"] = undefined : tenant["leaseTypeCode"] = leaseTypeForAll);
        }
        setTenantList([...tenantList]);
        // eslint-disable-next-line
    }, [leaseTypeSameType, leaseTypeForAll]);

    useEffect(() => {
        if (!hasFreeMonth) {
            tenantList.forEach((tenant) => tenant["freeRentMonth"] = undefined);
        }
        setTenantList([...tenantList]);
        // eslint-disable-next-line
    }, [hasFreeMonth]);

    useEffect(() => {
        if (!hasLandLease) {
            tenantList.forEach((tenant) => tenant["landLeaseFlag"] = undefined);
        }
        setTenantList([...tenantList]);
        // eslint-disable-next-line
    }, [hasLandLease]);

    useEffect(() => {
        if (!hasStepUp) {
            tenantList.forEach((tenant) => {
                tenant["stepUpDate1"] = undefined
                tenant["stepUpRate1"] = undefined
                tenant["stepUpDate2"] = undefined
                tenant["stepUpRate2"] = undefined
                tenant["stepUpDate3"] = undefined
                tenant["stepUpRate3"] = undefined
                tenant["stepUpDate4"] = undefined
                tenant["stepUpRate4"] = undefined
            });
            setStepUpArr([]);
        }
        setTenantList([...tenantList]);
        // eslint-disable-next-line
    }, [hasStepUp]);

    useEffect(() => {
        if (confirmedImport === false) {
            setTenantList([]);
        }
    }, [confirmedImport]);

    const addMultipleUnits = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(postMultipleIncomeUnits(props.snapshotId, [...tenantList, ...confirmedTenants], 'c'));
        setSubmitted(true);
    }

    return (<LoadingSpinner loading={postIncomeUnit.loading || !props.snapshotId}>
        <div className='guided-experience-wizard'>
            <div>
                <p>{confirmedImport !== undefined && (t("step-1") + (currentWizardPage + 1) + t("step-2") + (confirmedImport === true ? importWizardElements.length : orderedWizardElements.length))}</p>
                {confirmedImport === undefined ? 
                    <ConfirmImportWizard key="wizard-confirm-import" setConfirmedImport={setConfirmedImport} confirmedImport={confirmedImport} /> :
                    confirmedImport === true ?
                    (getPreviousTenantSummaryState.loading ? <LoadingSpinner loading={true} /> : importWizardElements[currentWizardPage]):
                    orderedWizardElements[currentWizardPage]}
            </div>
            <div className='guided-experience-wizard-buttons'>
                <table>
                   <tbody>
                        <tr>
                            <td>
                                <Button type='cancel' onClick={() => {setClosingWizard(true); setWarningMessage(t("discard"))}} >
                                    {t("cancel").toLocaleUpperCase(i18n.language)}
                                </Button>
                            </td>
                            <td>
                                {((confirmedImport === true ? importWizardElements.length > 1 && currentWizardPage > 2 : orderedWizardElements.length > 1 && currentWizardPage !== 0) && !(confirmedArr.length === [...tenantList, ...confirmedTenants].length && confirmedArr.length !== 0)) && <Button type='secondary' onClick={() => previousWizardPage()} >
                                    {t("back").toLocaleUpperCase(i18n.language)}
                                </Button> }
                            </td>
                            <td>
                                {currentWizardPage !== ((confirmedImport === true ? importWizardElements.length : orderedWizardElements.length) - 1) && 
                                    (confirmedImport === true ? importWizardElements.length : orderedWizardElements.length) > 1 && <Button type='secondary' onClick={() => nextWizardPage()} >
                                    {t("next").toLocaleUpperCase(i18n.language)}
                                </Button> }
                            </td>
                            <td>
                                {currentWizardPage === ((confirmedImport === true ? importWizardElements.length : orderedWizardElements.length) - 1) && (confirmedImport === true ? importWizardElements.length : orderedWizardElements.length) > 1 && <Button type='submit' onClick={addMultipleUnits} >
                                    {t("add-units-1").toLocaleUpperCase(i18n.language) + [...tenantList, ...confirmedTenants].length + t("add-units-2").toLocaleUpperCase(i18n.language)}
                                </Button> }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        {(showWarningModal || closingWizard) && 
            <Modal onCloseModal={() => setShowWarningModal(false)}>
                <div className='guided-wizard-warning-modal'>
                    <p>{warningMessage}</p>
                    <div className='guided-wizard-warning-buttons'>
                        <Button type='cancel' onClick={() => {setShowWarningModal(false); setClosingWizard(false); setWarningMessage("")}} className='guided-wizard-warning-cancel'>
                            {t("cancel").toLocaleUpperCase(i18n.language)}
                        </Button>
                        <Button type='secondary' onClick={() => setProceed(true)} >
                            {t("yes").toLocaleUpperCase(i18n.language)}
                        </Button>
                    </div>
                </div>
            </Modal>
        }
    </LoadingSpinner>)
}