export interface LoadingState {
    loading: number;
}

const initialState: LoadingState = {
    loading: 0
}

function resetOnRefresh() {
    return initialState
}

export default resetOnRefresh;
