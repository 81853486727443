export default function formatCurrency(value: number | undefined | null, language: string) {
  if (typeof value === "number") {
    const farmattedValue = value.toLocaleString(language, {
      style: 'currency',
      currency: "CAD",
      currencyDisplay: "code",
    }).replaceAll("CAD", '').trim()
    return farmattedValue
  }
  return value
}