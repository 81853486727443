import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../common/CurrencyInput/CurrencyInput';
import DatePicker from '../common/DatePicker/DatePicker';
import "./CommercialUnit.css";

interface StepDateProps {
    formData: Record<string, unknown>,
    handleDateChange: (date: Date | null | undefined, name: string) => void,
    handleCurrencyChange: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
    currencySymbol?: string,
    excludeTBody?: boolean,
    colspan?: number,
    errors?: Record<string, string>
    headers?: string[];
    disabled?: boolean;
}

export default function StepDate(props: StepDateProps): JSX.Element {
    const { t } = useTranslation('unit');

    const content = [];
    for (let i = 1; i <= 4; i++) {
        const dateFieldName = "stepUpDate" + i;
        const currencyFieldName = "stepUpRate" + i;
        content.push(
            <tr key={i}>
                <td colSpan={props.colspan} headers={(props.headers && props.headers.length > 0) ? props.headers[0] : undefined}>
                    <DatePicker name={dateFieldName} id={dateFieldName}
                        date={props.formData[dateFieldName] as string | undefined}
                        onChange={props.handleDateChange}
                        position='left'
                        placeholderText={t('headings.step-date' + i + '-placeholder')}
                        title={t('headings.step-date' + i + '-placeholder')}
                        disabled={props.disabled}
                    />
                </td>
                <td width="160px" colSpan={props.colspan} headers={(props.headers && props.headers.length > 1) ? props.headers[1] : undefined}>
                    <CurrencyInput name={currencyFieldName} id={currencyFieldName}
                        value={props.formData[currencyFieldName] as number | undefined}
                        onChange={props.handleCurrencyChange}
                        symbol={props.currencySymbol}
                        placeholder={"(" + props.currencySymbol + ")"}
                        title={t('tooltips.step-rent-tooltip') + "(" + props.currencySymbol + ")"}
                        errormessage={props.errors && props.errors[currencyFieldName]}
                        disabled={props.disabled}
                    />
                </td>
            </tr>
        );
    }

    return props.excludeTBody ?  <>{content}</> : <tbody>{content}</tbody>    
        
   
}
