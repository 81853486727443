import { faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const LTCNavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false,
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'bedCounts',
        complete: false,
        componentName: "BedCounts"
      },
      {
        id: 22,
        name: 'basicProvincialSubsidy',
        complete: false,
        componentName: "Subsidy"
      },
      {
        id: 23,
        name: 'revenue',
        complete: false,
        componentName: 'RevenueContainer'
      },
      {
        id: 24,
        name: 'operatingExpenses',
        complete: false,
        componentName: 'ExpenseContainer'
      },
      {
        id: 25,
        name: 'fixedCharges',
        complete: false,
        componentName: 'ExpenseContainer'
      },
      {
        id: 26,
        name: 'comments',
        complete: false,
        componentName: 'Comment'
      }
    ],
  },
  {
    id: 3,
    name: 'summaryReview',
    complete: false,
    componentName: 'Summary',
    icon: faFilePdf
  },
  {
    id: 4,
    name: 'submit',
    complete: false,
    componentName: 'Submit',
    icon: faFileInvoiceDollar
  }
]

export default LTCNavList