import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/root'
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer'
import { getProfileAttributes, putProfileAttributes, resetGetProfileAttributes } from '../../../redux/actions/profile/profile'
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer'
import { ProfileAttributesState } from '../../../redux/reducers/getProfile/profileAttributes/getAttributesReducer'
import updateProperties from '../../../utils/DataModifier/DataModifier'
import DataTable from '../../../components/common/DataTable/DataTable'
import TableRow, { TableCellData } from '../../../components/common/TableRow/TableRow'
import Asterisk from '../../../components/common/Asterisk/Asterisk'
import "./Utilities.css"

interface AttributeDataType {
  "residentialHeatFlag": boolean | null,
  "residentialHydroFlag": boolean | null,
  "residentialWaterFlag": boolean | null,
  "residentialCableFlag": boolean | null,
  "residentialInternetFlag": boolean | null,
  "residentialPoolSaunaFitnessFlag": boolean | null,
  "residentialAirConditioningFlag": boolean | null,
  "residentialElevatorFlag": boolean | null,

  "residentialLaundryEnsuiteFlag": boolean | null,
  "residentialLaundryCommonFlag": boolean | null,

  "residentialStorageInternalFlag": boolean | null,
  "residentialStorageExternalFlag": boolean | null,

  "residentialAccessDoormanConciergeFlag": boolean | null,
  "residentialAccessControlledFlag": boolean | null,

  "residentialOnsiteSecurityFlag": boolean | null,
  "residentialOnsiteStaffFlag": boolean | null,
}
const initialAttributeData: AttributeDataType = {
  "residentialHeatFlag": null,
  "residentialHydroFlag": null,
  "residentialWaterFlag": null,
  "residentialCableFlag": null,
  "residentialInternetFlag": null,
  "residentialPoolSaunaFitnessFlag": null,
  "residentialAirConditioningFlag": null,
  "residentialElevatorFlag": null,

  "residentialLaundryEnsuiteFlag": null,
  "residentialLaundryCommonFlag": null,

  "residentialStorageInternalFlag": null,
  "residentialStorageExternalFlag": null,

  "residentialAccessDoormanConciergeFlag": null,
  "residentialAccessControlledFlag": null,

  "residentialOnsiteSecurityFlag": null,
  "residentialOnsiteStaffFlag": null,
}

function Utilities() {
  const dispatch = useDispatch()
  const { t } = useTranslation(['utilities', 'navigation'])
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
  const profileAttributes: ProfileAttributesState = useSelector((state: RootState) => state.profileReducer.getProfileAttributes as ProfileAttributesState)
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
  const [attributeData, setAttributeData] = useState<AttributeDataType>(initialAttributeData)
  const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)

  useEffect(() => {
    dispatch(resetGetProfileAttributes())
    dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!profileAttributes.loading && profileAttributes.data) {
      const initData = { ...initialAttributeData }
      updateProperties(initData, profileAttributes.data)
      setAttributeData(initData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileAttributes.data, profileAttributes.loading])

  const handleAttributeChange = (name: string, value: string | number | boolean | null) => {
    setAttributeData({
      ...attributeData,
      [name]: value
    })
  }

  useEffect(() => {
    if (saveData.saving) {
      const data = { ...profileAttributes.data, ...attributeData } as Record<string, unknown>
      dispatch(putProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId, data))
    }
  }, [attributeData, currentSnapshotId, dispatch, profileAttributes.data, profileOverview.profile.profile_type_code, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileAttributes(profileOverview.profile.profile_type_code, currentSnapshotId))
    }
  }, [currentSnapshotId, dispatch, profileOverview.profile.profile_type_code, saveResultState.status])

  const keys = Object.keys(attributeData)
  const inclusionsHeadings = [
    <th title={t("titleHeat")} id='heat' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerHeat")}</th>,
    <th title={t("titleHydro")} id='hydro' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerHydro")}</th>,
    <th title={t("titleWater")} id='water' key="inclusions-column-4-header" className='pier-datatable-centered-th'>{t("headerWater")}</th>,
    <th title={t("titleCable")} id='cable' key="inclusions-column-5-header" className='pier-datatable-centered-th'>{t("headerCable")}</th>,
    <th title={t("titleInternet")} id='internet' key="inclusions-column-6-header" className='pier-datatable-centered-th'>{t("headerInternet")}</th>,
    <th title={t("titlePoolSaunaFitness")} id='poolSaunaFitness' key="inclusions-column-7-header" className='pier-datatable-centered-th'>{t("headerPoolSaunaFitness")}</th>,
    <th title={t("titleAirConditioning")} id='airConditioning' key="inclusions-column-8-header" className='pier-datatable-centered-th'>{t("headerAirConditioning")}</th>,
    <th title={t("titleElevator")} id='elevator' key="inclusions-column-9-header" className='pier-datatable-centered-th'>{t("headerElevator")}</th>,
  ]
  const inclusions: TableCellData[] = [
    { name: keys.filter((key) => key.includes("Heat"))[0], inputType: "checkbox", header: "heat" },
    { name: keys.filter((key) => key.includes("Hydro"))[0], inputType: "checkbox", header: "hydro" },
    { name: keys.filter((key) => key.includes("Water"))[0], inputType: "checkbox", header: "water" },
    { name: keys.filter((key) => key.includes("Cable"))[0], inputType: "checkbox", header: "cable" },
    { name: keys.filter((key) => key.includes("Internet"))[0], inputType: "checkbox", header: "internet" },
    { name: keys.filter((key) => key.includes("PoolSaunaFitness"))[0], inputType: "checkbox", header: "poolSaunaFitness" },
    { name: keys.filter((key) => key.includes("AirConditioning"))[0], inputType: "checkbox", header: "airConditioning" },
    { name: keys.filter((key) => key.includes("Elevator"))[0], inputType: "checkbox", header: "elevator" },
  ]

  const laundryHeadings = [
    <th title={t("titleLaundryEnsuite")} id='laundryEnsuite' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerLaundryEnsuite")}</th>,
    <th title={t("titleLaundryCommon")} id='laundryCommon' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerLaundryCommon")}</th>,
  ]
  const laundryInclusions: TableCellData[] = [
    { name: keys.filter((key) => key.includes("LaundryEnsuite"))[0], inputType: "checkbox", header: "LaundryEnsuite" },
    { name: keys.filter((key) => key.includes("LaundryCommon"))[0], inputType: "checkbox", header: "LaundryCommon" },
  ]

  const storageHeadings = [
    <th title={t("titleStorageInternal")} id='storageInternal' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerStorageInternal")}</th>,
    <th title={t("titleStorageExternal")} id='storageExternal' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerStorageExternal")}</th>,
  ]
  const storageInclusions: TableCellData[] = [
    { name: keys.filter((key) => key.includes("StorageInternal"))[0], inputType: "checkbox", header: "StorageInternal" },
    { name: keys.filter((key) => key.includes("StorageExternal"))[0], inputType: "checkbox", header: "StorageExternal" },
  ]

  const accessHeadings = [
    <th title={t("titleAccessDoormanConcierge")} id='accessDoormanConcierge' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerAccessDoormanConcierge")}</th>,
    <th title={t("titleAccessControlled")} id='accessControlled' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerAccessControlled")}</th>,
  ]
  const accessInclusions: TableCellData[] = [
    { name: keys.filter((key) => key.includes("AccessDoormanConcierge"))[0], inputType: "checkbox", header: "AccessDoormanConcierge" },
    { name: keys.filter((key) => key.includes("AccessControlled"))[0], inputType: "checkbox", header: "AccessControlled" },
  ]

  const onsiteHeadings = [
    <th title={t("titleOnsiteSecurity")} id='onsiteSecurity' key="inclusions-column-2-header" className='pier-datatable-centered-th'>{t("headerOnsiteSecurity")}</th>,
    <th title={t("titleOnsiteStaff")} id='onsiteStaff' key="inclusions-column-3-header" className='pier-datatable-centered-th'>{t("headerOnsiteStaff")}</th>,
  ]
  const onsiteInclusions: TableCellData[] = [
    { name: keys.filter((key) => key.includes("OnsiteSecurity"))[0], inputType: "checkbox", header: "OnsiteSecurity" },
    { name: keys.filter((key) => key.includes("OnsiteStaff"))[0], inputType: "checkbox", header: "OnsiteStaff" },
  ]

  return (
    <>
      <div className='assisted-suite-breakdown-container'>
        <span><Asterisk /> {t("headerAmenities")}</span>
        <DataTable headings={inclusionsHeadings} className='utilities-table'>
          <TableRow
            cellData={inclusions}
            dataType={{ ...attributeData } as Record<string, boolean | null>}
            onChange={handleAttributeChange} />
        </DataTable>
        <br />
        <span>{t("laundry")}</span>
        <DataTable headings={laundryHeadings} className='utilities-table'>
          <TableRow
            cellData={laundryInclusions}
            dataType={{ ...attributeData } as Record<string, boolean | null>}
            onChange={handleAttributeChange} />
        </DataTable>
        <br />
        <span>{t("storage")}</span>
        <DataTable headings={storageHeadings} className='utilities-table'>
          <TableRow
            cellData={storageInclusions}
            dataType={{ ...attributeData } as Record<string, boolean | null>}
            onChange={handleAttributeChange} />
        </DataTable>
        <br />
        <span>{t("access")}</span>
        <DataTable headings={accessHeadings} className='utilities-table'>
          <TableRow
            cellData={accessInclusions}
            dataType={{ ...attributeData } as Record<string, boolean | null>}
            onChange={handleAttributeChange} />
        </DataTable>
        <br />
        {t("onsite")}
        <DataTable headings={onsiteHeadings} className='utilities-table'>
          <TableRow
            cellData={onsiteInclusions}
            dataType={{ ...attributeData } as Record<string, boolean | null>}
            onChange={handleAttributeChange} />
        </DataTable>

      </div>
    </>
  )
}

export default Utilities