/**
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content and cast data to IncomeAttributeLookupObject
*  Update: 18/04/2024 - Mariam Bawa - Added thead element in markup to meet AODA standards. 
Element is then hidden from UI using CSS
*/
import React, { useEffect, useState } from "react";
import IncomeAttributeTableFragmentGenerator from "../../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import { LookupsState } from "../../../redux/reducers/getLookups/lookupsReducer";
import { IncomeAttributeState } from "../../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import { useTranslation } from "react-i18next";
import { getLookups } from "../../../redux/actions/lookups/lookups";
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../../redux/actions/incomeAttributes/incomeAttributes";
import { resetSaving } from "../../../redux/actions/saveData/saveData";
import MenuState from "../../../types/menu/MenuState";
import IncomeAttributeLookupObject from "../../../types/lookups/IncomeAttributeLookupObject";
import { GlobalSettingState } from "../../../redux/reducers/getGlobalSetting/getGlobalSettingReducer";
import "../PIE.css";
import { useReadOnly } from "../../../utils/ReadOnlyContext";
import { SaveResultState } from "../../../redux/reducers/getSaveData/saveResultReducer";
import { setNextActiveMenuItem } from "../../../redux/actions/menu/menu";
import { initialMenuItem } from "../../../redux/reducers/getMenu/getMenuReducer";
import { IncomeAttributeLookupCategories } from "../../../types/lookups/IncomeAttributeLookupState";
import formatCurrency from "../../../utils/formatCurrency";

export default function IncomeAttributeTable(): JSX.Element {
  const { i18n } = useTranslation();
  const { t } = useTranslation("pie");
  const dispatch = useDispatch();
  const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
  const lookupState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
  const activeMenuName = menuState.activeItem.name;
  let total = 0;
  const replacementReserveAttributeCode = "50";
  const atLeastOne = t(activeMenuName + ".atLeastOne") === "true";
  const required = t(activeMenuName + ".required");
  const requiredAttributeCodes: string[] = required.endsWith(".required") ? [] : JSON.parse(required);
  const [errs, setErrs] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [lookups, setLookups] = useState<IncomeAttributeLookupObject[]>([]);
  const { isReadOnly } = useReadOnly()

  useEffect(() => {
    if (!lookupState.success && !lookupState.loading) {
      const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
      dispatch(getLookups(lookupType));
    }
  }, [dispatch, lookupState.loading, lookupState.success, profileOverview.profile.profile_type_code]);

  useEffect(() => {
    let lookups = [] as IncomeAttributeLookupObject[];
    if (lookupState.success) {
      const categorys = Object.keys(lookupState.lookups);
      for (let c = 0; c < categorys.length; c++) {
        const category = categorys[c] as IncomeAttributeLookupCategories;
        const pages = Object.keys(lookupState.lookups[category]);
        if (pages.includes(activeMenuName)) {
          lookups = lookupState.lookups[category][activeMenuName];
          break;
        }
      }
      setLookups(lookups);
    }
  }, [activeMenuName, lookupState.lookups, lookupState.success]);

  useEffect(() => {
    if ((!incomeAttributesState.loading && !incomeAttributesState.success)
      || incomeAttributesState.snapshotId !== Number(localStorage.getItem('currentSnapshotId') as string)
    ) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
    // eslint-disable-next-line
  }, []);

  const findAttributeData = (attributeCode: string) => {
    let incomeAttributesData: Record<string, unknown> | undefined = undefined;
    if (incomeAttributesState.data) {
      incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
    }
    return incomeAttributesData;
  };

  const validate = (showError = true) => {
    let valid = true;
    if (localStorage.getItem("isInternalUser")) {
      valid = true;
    } else {
      const errs: Record<string, string> = {};
      if (atLeastOne) {
        errs["atLeastOne"] = "";
        valid = total > 0;
        if (!valid) {
          errs["atLeastOne"] = 'validation.atLeastOne';
        }
      }

      const requiredAttributeCodes: string[] = required.endsWith(".required") ? [] : JSON.parse(required);
      if (requiredAttributeCodes.length > 0) {
        requiredAttributeCodes.forEach((code) => {
          errs[code] = "";
          const attributeData = findAttributeData(code);
          if (!attributeData || !attributeData.value) {
            valid = false;
            errs[code] = 'validation.' + code;
          }
        });
      }
      if (showError) {
        setErrs(errs);
      }
    }

    if (isReadOnly) {
      valid = true;
    }
    return valid;
  }

  // useEffect(() => {
  //   if (!incomeAttributesState.loading && incomeAttributesState.success && !validate(false) && !saveData.changesPending) {
  //     dispatch(setChangesMade());
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch, incomeAttributesState])

  useEffect(() => {
    if (saveData.saving) {
      if (validate()) {
        const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
        dispatch(putIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string), incomeAttributes));
      } else {
        dispatch(resetSaving());
        dispatch(setNextActiveMenuItem(initialMenuItem));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, incomeAttributesState.data, saveData.saving])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getIncomeAttributesBySnapshotId(Number(localStorage.getItem('currentSnapshotId') as string)));
    }
  }, [dispatch, saveResultState]);

  const findLookupData = (attributeCode: string) => {
    let lookupData: IncomeAttributeLookupObject | undefined;
    if (lookups.length > 0) {
      lookupData = lookups.find(({ incomeAttributeCode }) => incomeAttributeCode === attributeCode);
    }
    return lookupData;
  };

  const handleCurrencyChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const na = newAmt ? newAmt : null;
      if (incomeAttributesData) {
        incomeAttributesData["value"] = na;
      } else {
        const lookupData = findLookupData(code);
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: null,
          value: na,
          type: lookupData?.incomeAttributeTypeCode,
          percent: null,
          year: null,
        });
      }
    }
  }

  const handleCommentChange = (value: string, name: string) => {
    const idx = name.lastIndexOf("-");
    if (idx !== -1) {
      const code = name.substring(idx + 1);
      const incomeAttributesData = findAttributeData(code);
      const v = value.trim() === "" ? null : value.trim();
      if (incomeAttributesData) {
        incomeAttributesData["description"] = v;
      } else {
        const lookupData = findLookupData(code);
        (incomeAttributesState.data as Record<string, unknown>[]).push({
          snapshotId: Number(localStorage.getItem('currentSnapshotId') as string),
          code: code,
          description: v,
          value: null,
          type: lookupData?.incomeAttributeTypeCode,
          percent: null,
          year: null
        });
      }
    }
  }

  useEffect(() => {
    const translatedErrors: Record<string, string> = {};
    Object.keys(errs).forEach((key) => {
      translatedErrors[key] = t(errs[key]);
    });
    setErrors(translatedErrors);
  }, [errs, t]);

  useEffect(() => {
    setErrs({});
  }, [menuState.activeItem]);

  return (
    <>
      {
        atLeastOne ?
          <p className="imp-note">
            {t(activeMenuName + ".atLeaseOneText")}
          </p>
          :
          null
      }
      <p>
        {t(activeMenuName + ".note")}
      </p>
      {
        (!lookupState.loading && !incomeAttributesState.loading) &&
        <table className="table-with-border pie-table">
          <thead>
            <tr>
              <th id="attribute-label" scope="col"></th>
              <th id="attribute-value" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              lookups
              && lookups.map((attr, key) => {
                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                if (incomeAttributesState.data) {
                  incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attr.incomeAttributeCode);
                  if (incomeAttributesData && incomeAttributesData.value
                    && incomeAttributesData.code !== replacementReserveAttributeCode) {
                    total = total + (incomeAttributesData["value"] as number);
                  }
                }
                if (["7", "114"].includes(attr.incomeAttributeCode)) {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    handleCommentChange={handleCommentChange}
                    valueChangeHandler={handleCurrencyChange}
                    showSubheading={false}
                    hasInputLabel={true} isPlaceHolderWithAttrDesc={true}
                    isOtherLabel={false}
                    isRequiredField={requiredAttributeCodes.includes(attr.incomeAttributeCode)}
                    isReplacementReserve={replacementReserveAttributeCode === attr.incomeAttributeCode}
                    errors={errors}
                  />
                } else {
                  return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                    data={incomeAttributesData}
                    handleCommentChange={handleCommentChange}
                    valueChangeHandler={handleCurrencyChange}
                    showSubheading={false}
                    hasInputLabel={false}
                    isOtherLabel={false}
                    isRequiredField={requiredAttributeCodes.includes(attr.incomeAttributeCode)}
                    isReplacementReserve={replacementReserveAttributeCode === attr.incomeAttributeCode}
                    errors={errors}
                  />
                }
              }
              )
            }
          </tbody>
        </table>

      }
      <p className="imp-note">{errors["atLeastOne"]}</p>

      <div className='tab-container-footer'>
        <div className="total-income">
          <p>{t(activeMenuName + '.total')}</p>
          <p>{(globalSettingState.setting["currency"] as Record<string, unknown>)["symbol"] as string} {formatCurrency(total, i18n.language)}</p>
        </div>
      </div>

    </>
  );
}
