import { faBuilding, faCartShopping, faFileInvoiceDollar, faFilePdf, faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons"
import MenuItem from "../../../types/menu/MenuItem"

const MTLNavList: MenuItem[] = [
  {
    id: 1,
    name: 'contactInfo',
    componentName: 'ContactInfo',
    complete: false
  },
  {
    id: 2,
    name: 'incomeExpense',
    componentName: 'IncomeExpense',
    complete: false,
    icon: faMoneyBillTrendUp,
    subItems: [
      {
        id: 21,
        name: 'roomStatistics',
        componentName: 'RoomStatistics',
        complete: false
      },
      {
        id: 22,
        name: 'motelRevenue',
        componentName: 'MotelRevenue',
        complete: false
      },
      {
        id: 23,
        name: 'directProductCost',
        componentName: 'DirectProductCost',
        complete: false
      },
      {
        id: 24,
        name: 'undistributedExpenses',
        componentName: 'UndistributedExpenses',
        complete: false
      },
      {
        id: 25,
        name: 'fixedCharges',
        componentName: 'FixedCharges',
        complete: false
      },
      {
        id: 26,
        name: 'reserveForReplacementAllowance',
        componentName: 'ReserveForReplacementAllowance',
        complete: false
      },
      {
        id: 27,
        name: 'capitalCostsSummary',
        componentName: 'CapitalCostsSummary',
        complete: false
      },
      {
        id: 28,
        name: 'comments',
        componentName: 'Comment',
        complete: false
      }
    ],
  },
  {
    id: 3,
    name: 'apartment',
    complete: false,
    icon: faBuilding,
    subItems: [
      {
        id: 31,
        name: 'suiteBreakdownTable',
        componentName: 'SuiteBreakdown',
        complete: false
      }
    ]
  },
  {
    id: 4,
    name: 'commercial',
    complete: false,
    icon: faCartShopping,
    subItems: [
      {
        id: 41,
        name: 'leaseInformationTable',
        componentName: 'LeaseInfo',
        complete: false
      },
      {
        id: 42,
        name: 'parkingPropertySummary',
        complete: false,
        componentName: 'ParkingPropertySummary',
      }
    ]
  },
  {
    id: 5,
    name: 'summaryReview',
    componentName: 'Summary',
    complete: false,
    icon: faFilePdf
  },
  {
    id: 6,
    name: 'submit',
    componentName: 'Submit',
    complete: false,
    icon: faFileInvoiceDollar
  }
]

export default MTLNavList