import React, { useEffect, useState } from "react";
import LandingBanner from "../../components/LandingBanner/LandingBanner";
import Header from "../../layouts/Header/Header";
import Footer from "../../layouts/Footer/Footer";
import "../Landing/Landing.css";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import ValidateInternalTokenState from "../../types/auth/ValidateInternalTokenState";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/root";
import { searchProfileByRoll } from "../../redux/actions/profile/searchRoll";
import { ProfileByRollState } from "../../redux/reducers/getProfile/getProfileByRollReducer";
import { CampaignListByRoll } from "../../types/profile/CampaignListByRoll";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faPrint } from "@fortawesome/free-solid-svg-icons";
import "../Landing/Landing.css";
import "./InternalLanding.css";
import Pagination from "../../components/common/Pagination/Pagination";
import parseStatus from "../../utils/parseStatus";
import { useNavigate } from "react-router-dom";
import { getProfileOverview } from "../../redux/actions/profile/profile";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import { updateCampaignStatus } from "../../redux/actions/campaign/updateStatus";
import { EXTERNAL_IN_PROGRESS, INTERNAL_IN_PROGRESS } from "../../redux/actionTypes/actionsTypes";
import { UpdateCampaignState } from "../../redux/reducers/getCampaign/updateCampaignStatusReducer";
import i18n from "../../i18n";
import { getIncomeFile } from "../../redux/actions/file/file";
import { FileState } from "../../redux/reducers/getFile/getFileReducer";
import { GeneratePDFPayload } from "../../types/pdf/GeneratePDFPayload";
import { setReadOnly } from "../../redux/actions/readOnly/readOnly";
import { ProfileDetailsState } from "../../redux/reducers/getProfile/getProfileDetailsReducer";

export default function InternalLanding(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validateInternalTokenStore = useSelector((state: RootState) => state.authReducer.validateInternalToken as ValidateInternalTokenState);
  const searchRollStore = useSelector((state: RootState) => state.profileReducer.getProfileByRoll as ProfileByRollState);
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
  const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus);
  const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file);
  const [isRollSearchReqSent, setIsRollSearchReqSent] = useState(false);
  const [isProfileReqSent, setIsProfileReqSent] = useState(false);
  const [isStatusReqSent, setIsStatusReqSent] = useState(false);
  const [reviewing, setReviewing] = useState(false)
  const [isProfileDetailsReqSent, setIsProfileDetailsReqSent] = useState(false)
  const [campaignList, setCampaignList] = useState<CampaignListByRoll[]>([])

  const [downloading, setDownloading] = useState(false);
  const [selectedCampaignYear, setSelectedCampaignYear] = useState(new Date().getFullYear());
  // Pagination related states and methods
  const [pageData, setPageData] = useState<CampaignListByRoll[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);

  const roll = localStorage.getItem('rollNumber');

  useEffect(() => {
    roll && dispatch(searchProfileByRoll(roll));
    setIsRollSearchReqSent(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (campaignList) {
      setPageData(campaignList.slice(itemOffset, endOffset));
      setTotalPages(Math.ceil(campaignList.length / itemsPerPage));
    }
  }, [campaignList, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (isRollSearchReqSent && !searchRollStore.loading) {
      if (searchRollStore.success && searchRollStore.campaigns) {
        const excludeYears = [2020]
        const campaignList = searchRollStore.campaigns.filter((campaign) => !excludeYears.includes(campaign.year))
        setCampaignList(campaignList)
        // const endOffset = itemOffset + itemsPerPage;
        // setPageData(campaignList.slice(itemOffset, endOffset));
        // setTotalPages(Math.ceil(campaignList.length / itemsPerPage));
      }
      else if (!searchRollStore.success) {
        toast.error(searchRollStore.message);
      }
      setIsRollSearchReqSent(false)
    }
  }, [isRollSearchReqSent, searchRollStore.campaigns, searchRollStore.loading, searchRollStore.message, searchRollStore.success]);

  const handleItemsPerPage = (itemCount: number) => {
    setItemsPerPage(itemCount);
  }

  const handlePageClick = ({ selected }: { selected: number }) => {
    if (pageData && campaignList) {
      const newOffset = (selected * itemsPerPage) % campaignList.length;
      setItemOffset(newOffset);
    }
  };

  useEffect(() => {
    if ((reviewing || isProfileReqSent) && !profileOverview.loading && profileOverview.found) {
      setIsProfileReqSent(false);
      setReviewing(false)
      navigate('/portal');
    }
    else if (isProfileReqSent && profileOverview.error) {
      toast.error("Failed to load data");
      setIsProfileReqSent(false);
    }
  }, [isProfileReqSent, profileOverview, navigate, reviewing])

  // This function verifies snapshot type 2 exists for campaign. If it exists, then read only path begins
  // const startReadOnlyPath = (snapshotList: [], year: string) => {
  //   const type2 = snapshotList.filter((e) => e['snapshotTypeCode'] === "2");

  //   if (!type2) {
  //     toast.error("Error: No snapshot type 2 found");
  //   }
  //   else if (type2.length === 1) {
  //     localStorage.setItem("currentSnapshotId", type2[0]["snapshotId"]);
  //     localStorage.setItem("activeCampaignYear", year);
  //     dispatch(getProfileOverview(String(searchRollStore.profileID), searchRollStore.profileTypeCode));
  //     setIsProfileReqSent(true);
  //   }
  //   else if (type2.length > 1) {
  //     toast.error("Error: Found multiple snapshot type 2 on this campaign");
  //   }
  // }

  const isNumericOnly = (str: string): boolean => /^\d+$/.test(str);

  const startModifiedSubmission = (profileId: string, campaignId: string, year: string, statusCode: string, snapshots: [] | undefined) => {
    localStorage.setItem("activeCampaignYear", year);
    localStorage.setItem('campaignId', campaignId);
    localStorage.setItem('profileId', profileId);
    if (snapshots){
      if (statusCode === "EXTERNAL_IN_PROGRESS") {
        const type2snapshot = snapshots.find(snapshot => snapshot.snapshotTypeCode === "2");
        if (type2snapshot && isNumericOnly(type2snapshot.createdBy)) {
          toast.error("Cannot modify submission while external user is modifying it.");
          return;
        }
        if (type2snapshot) {
          dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS, profileId, campaignId));
          setIsStatusReqSent(true);
        } else {
          toast.error("Snapshot type 2 not found.");
        }
      } else if (statusCode === "NEW_CREATION") {
        dispatch(updateCampaignStatus(EXTERNAL_IN_PROGRESS, profileId, campaignId));
        setIsStatusReqSent(true);
      } else if ("INTERNAL_IN_PROGRESS" === statusCode) {
        const type3snapshot = snapshots.find(snapshot => snapshot.snapshotTypeCode === "3");
        if (type3snapshot) {
          dispatch(updateCampaignStatus(INTERNAL_IN_PROGRESS, profileId, campaignId));
          setIsStatusReqSent(true);
        }
      } else if ("EXTERNAL_SUBMITTED" === statusCode) {
        dispatch(updateCampaignStatus(INTERNAL_IN_PROGRESS, profileId, campaignId));
        setIsStatusReqSent(true);
      }
      localStorage.setItem('campaignStatusCode', statusCode);
    }
    else {
      toast.error("No snapshots found for this campaign.");
    }
  };

  const startReadOnlyMode = (profileId: string, year: string, currentSnapshotId: string) => {
    localStorage.setItem("activeCampaignYear", year);
    localStorage.setItem("currentSnapshotId", currentSnapshotId);
    localStorage.setItem("editMode", "true");
    dispatch(setReadOnly(true))
    dispatch(getProfileOverview(profileId));
    setReviewing(true);
  }

  const downloadSubmissionPDF = (campaignYear: number, campaignId: number) => {
    setSelectedCampaignYear(campaignYear);
    const payload: GeneratePDFPayload = {
      language: i18n.language,
      rollNumber: roll as string,
      profileId: searchRollStore.profileID,
      campaignYear: campaignYear,
      campaignId: campaignId,
      snapshotRequestType: "2"
    }
    dispatch(getIncomeFile(payload));
    setDownloading(true);
  };

  useEffect(() => {
    if (downloading && fileState.loading === false) {
      setDownloading(false);
      if (fileState.status === 200) {
        const base64string = "data:application/pdf;base64," + fileState.pdf;
        const downloadLink = document.createElement("a");
        downloadLink.href = base64string;
        downloadLink.download = roll + "_" + selectedCampaignYear + "_PIER.pdf";
        downloadLink.click();
      } else {
        toast.error(fileState.message);
      }
    }
  }, [downloading, fileState, selectedCampaignYear, roll]);

  useEffect(() => {
    if(isProfileDetailsReqSent && !profileDetails.loading) {
      setIsProfileDetailsReqSent(false)
    }
  }, [isProfileDetailsReqSent, profileDetails])


  useEffect(() => {
    if (isStatusReqSent && !updateCampaignStatusState.loading && updateCampaignStatusState.updated) {
      localStorage.setItem("currentSnapshotId", updateCampaignStatusState.campaign["currentSnapshotId"] as string);
      localStorage.setItem("editMode", "true");
      dispatch(setReadOnly(false))
      setIsStatusReqSent(false);
      dispatch(getProfileOverview(searchRollStore.profileID));
      setIsProfileReqSent(true);
    }
    else if (isStatusReqSent && updateCampaignStatusState.error) {
      toast.error("Failed to update status");
      setIsStatusReqSent(false);
    }
  }, [updateCampaignStatusState, isStatusReqSent, dispatch, navigate, searchRollStore]);

  return (<>
    <Header showAutoSave={false} isInternalUser={true} />
    <LandingBanner />
    <LoadingSpinner loading={validateInternalTokenStore.loading || profileOverview.loading}>
      <div className="landing-body-container">
        <div className="landing-body">
          <p className="select-text">First, select the income profile you wish to work with for roll {localStorage.getItem('rollNumber')}</p>
          <LoadingSpinner loading={searchRollStore.loading || updateCampaignStatusState.loading}>
            <div className="profile-list-container">
              <table className='profile-table' cellSpacing="0" cellPadding="0">
                <thead>
                  <tr className='profile-table-heading-container'>
                    <th>Roll number</th>
                    <th>Status</th>
                    <th>Campaign Year</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='profile-table-body'>
                  {pageData && (pageData).map((campaign, index) => {
                    return (
                      <tr key={`campaign-${index}`}>
                      <td>{roll}</td>
                      <td>{parseStatus(campaign.statusCode as string)}</td>
                      <td>{campaign.year}</td>
                      <td className={'action-icon-list'}>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => {
                            const snapshots = campaign.snapshots;
                            if (snapshots) {
                              const snapshotType2 = snapshots.find(
                                (s) => s.snapshotTypeCode === '2'
                              );
                              if (snapshotType2) {
                                startReadOnlyMode(
                                  String(searchRollStore.profileID),
                                  String(campaign.year),
                                  String(snapshotType2.snapshotId)
                                );
                                dispatch(setReadOnly(true));
                              } else {
                                toast.error('Error: No snapshot type 2 found');
                              }
                            } else {
                              toast.error('Error: No snapshots found for this campaign');
                            }
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            startModifiedSubmission(
                              String(searchRollStore.profileID),
                              String(campaign.campaignId),
                              String(campaign.year),
                              campaign.statusCode as string,
                              campaign.snapshots);
                            dispatch(setReadOnly(false));
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faPrint}
                          onClick={() =>
                            downloadSubmissionPDF(campaign.year, campaign.campaignId)
                          }
                        />
                      </td>
                    </tr>)
                  })}
                </tbody>
              </table>
              <Pagination
                breakLabel='...'
                nextLabel='Next'
                previousLabel='Previous'
                onPageChange={handlePageClick}
                itemsPerPage={itemsPerPage}
                totalPages={totalPages}
                setItemsPerPage={handleItemsPerPage} />
            </div>
          </LoadingSpinner>
        </div>
      </div>
    </LoadingSpinner>
    <Footer />
  </>
  );
}